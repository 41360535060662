<template>
  <div class="container configuration-form-container">
    <div class="control add-global-controls">
      <b-button
        v-if="this.permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openStripePortal()"
        class="no-margin-bottom special-margin-top"
      >
        Ver información de planes y pago
      </b-button>

      <b-button
        v-if="permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openStampCheckouts()"
        class="no-margin-bottom special-margin-top"
      >
        Comprar timbres
      </b-button>

      <b-button
        v-if="permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openUsersCheckout()"
        class="no-margin-bottom special-margin-top"
      >
        Comprar usuario extra
      </b-button>

      <b-button
        v-if="permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openWarehousesCheckout()"
        class="no-margin-bottom special-margin-top"
      >
        Comprar almacén extra
      </b-button>

      <b-button
        v-if="permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openPosCheckout()"
        class="no-margin-bottom special-margin-top"
      >
        Comprar caja extra
      </b-button>

      <b-button
        v-if="this.permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openCFDIDocumentsCheckout()"
        class="no-margin-bottom special-margin-top"
        :disabled="
          companyInformation.paymentPlan.modules.main.customDocuments === 'S'
        "
      >
        Comprar CFDI personalizado
      </b-button>

      <b-button
        v-if="this.permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openCompanyNameCheckout()"
        class="no-margin-bottom special-margin-top"
        :disabled="
          companyInformation.paymentPlan.planId !== proPlusServicesPlanMonth &&
          companyInformation.paymentPlan.planId !== proPlusPlanMonth &&
          companyInformation.paymentPlan.planId !== masterPlanMonth
        "
      >
        Comprar razón social extra
      </b-button>

      <b-button
        v-if="permissions.main.stripePortal === 'S'"
        type="is-primary"
        @click="openCancelSubscription()"
        class="no-margin-bottom special-margin-top"
      >
        Cancelar subscripción
      </b-button>
    </div>

    <div class="configuration-form-hint-container">
      <p class="configuration-form-hint">
        En esta sección podrás modificar la información de tu empresa esta
        información será usada para facturación y contacto. Los campos
        requeridos tienen el marco
      </p>
      <span class="global-form-hint-color"> Morado. </span> Recuerda que no
      podrás generar CFDIS si no cuentas con la información de facturación
      requerida completa. Los timbres comprados pueden tardar un par de minutos
      en reflejarse en su cuenta.
    </div>

    <h3 class="configuration-form-division">Información general</h3>

    <div class="initial-dashboard-cards-container special-margin">
      <InformationCard
        name="Timbres mensuales
        incluidos disponibles"
        :value="`${companyInformation.availableMonthlyStamps} / ${companyInformation.paymentPlan.monthlyStamps}`"
        :max="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.monthlyStamps
            ? companyInformation.paymentPlan.monthlyStamps
            : companyInformation.availableStamps
        "
        format="bold"
        width="three-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Timbres
        comprados disponibles"
        :value="companyInformation.availableStamps"
        :max="companyInformation.availableStamps"
        format="bold"
        width="three-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Usuarios contratados"
        :value="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.usersQuantity
            ? companyInformation.paymentPlan.usersQuantity
            : 1
        "
        :max="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.usersQuantity
            ? companyInformation.paymentPlan.usersQuantity
            : 1
        "
        format="bold"
        width="three-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Almacenes contratados"
        :value="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.warehousesQuantity
            ? companyInformation.paymentPlan.warehousesQuantity
            : 1
        "
        :max="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.warehousesQuantity
            ? companyInformation.paymentPlan.warehousesQuantity
            : 1
        "
        format="bold"
        width="three-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Cajas contratados"
        :value="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.posQuantity
            ? companyInformation.paymentPlan.posQuantity
            : 1
        "
        :max="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.posQuantity
            ? companyInformation.paymentPlan.posQuantity
            : 1
        "
        format="bold"
        width="three-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="CFDI personalizado"
        :value="
          companyInformation.paymentPlan.modules.main.customDocuments === 'S'
            ? 'Si'
            : 'No'
        "
        format="bold"
        width="three-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Razones sociales extras contratadas"
        :value="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.companyNamesQuantity
            ? companyInformation.paymentPlan.companyNamesQuantity
            : 1
        "
        :max="
          companyInformation.paymentPlan &&
          companyInformation.paymentPlan.companyNamesQuantity
            ? companyInformation.paymentPlan.companyNamesQuantity
            : 1
        "
        format="bold"
        width="full-centered"
        :moreDetails="false"
      />
    </div>

    <div class="configuration-divided-information special-margin">
      <div class="control add-global-controls special-margin">
        <b-field class="file is-primary upload-button">
          <b-upload
            class="file-label"
            accept=".cer"
            @input="(value) => transformCertificate(value)"
          >
            <span class="file-cta">
              <span class="file-label"> Subir certificado SAT</span>
            </span>
          </b-upload>
        </b-field>

        <b-field class="file is-primary upload-button">
          <b-upload
            class="file-label"
            accept=".key"
            @input="(value) => transformKey(value)"
          >
            <span class="file-cta">
              <span class="file-label"> Subir llave privada SAT</span>
            </span>
          </b-upload>
        </b-field>
      </div>

      <h3 class="configuration-form-division">Información de facturación</h3>

      <b-field class="special-margin left-list-filters">
        <b-checkbox
          v-model="company.NO_FACTURACION"
          true-value="S"
          false-value="N"
          >El uso del sistema es solo para control interno, no requiero
          facturación</b-checkbox
        >
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Razón social (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Compañía"
            expanded
            icon="file-outline"
            :value="companyName"
            disabled
            required
          ></b-input>
        </b-field>
        <b-field
          label="RFC de la compañía (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: XAXX010101000"
            expanded
            icon="file-outline"
            :value="companyInformation.RFC"
            disabled
            required
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          expanded
          label-position="inside"
          class="special-margin"
          :type="{ 'is-danger': companyInvoiceRequiredErrors.NOMBRE_FAC }"
          :message="{
            'La razón social sin régimen no es válida':
              companyInvoiceRequiredErrors.NOMBRE_FAC,
          }"
        >
          <template slot="label">
            Razón social sin tipo de sociedad (Requerido)
            <b-tooltip
              multilined
              label="Si su compañía es 'Mi compañía S.A. de C.V.' poner solo 'Mi compañía'"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: Compañía"
            expanded
            icon="file-outline"
            v-model="company.NOMBRE_FAC"
            @input="
              (val) => (company.NOMBRE_FAC = company.NOMBRE_FAC.toUpperCase())
            "
            required
            :disabled="
              companyInformation.NO_FACTURACION === 'S' ||
              company.NO_FACTURACION === 'S'
            "
          ></b-input>
        </b-field>
        <b-field
          label="Régimen fiscal (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': companyInvoiceRequiredErrors.CLAVE_REGIMEN }"
          :message="{
            'El régimen fical no es valido':
              companyInvoiceRequiredErrors.CLAVE_REGIMEN,
          }"
        >
          <b-select
            placeholder="Ejemplo: Régimen fiscal"
            icon="briefcase-outline"
            expanded
            v-model="company.CLAVE_REGIMEN"
            required
            class="required-input"
            :disabled="
              companyInformation.NO_FACTURACION === 'S' ||
              company.NO_FACTURACION === 'S'
            "
          >
            <option value="" disabled>Seleccione un régimen</option>
            <option
              v-for="singleRegimen in regimenes"
              :value="singleRegimen._id"
              :key="singleRegimen._id"
            >
              {{ singleRegimen.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          expanded
          label-position="inside"
          :type="{ 'is-danger': companyInvoiceRequiredErrors.CERTIFICADO_SAT }"
          :message="{
            'El certificado SAT en base 64 no es válido':
              companyInvoiceRequiredErrors.CERTIFICADO_SAT,
          }"
        >
          <template slot="label">
            Certificado SAT en base 64 (Requerido)
            <b-tooltip
              multilined
              label="Si no cuenta con su certificado SAT en base 64 puede subir el archivo .cer en el botón 'Subir certificado SAT' y nosotros transformaremos el certificado a base 64"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: MIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQw..."
            expanded
            icon="directions-fork"
            v-model="company.CERTIFICADO_SAT"
            password-reveal
            type="password"
            required
            :disabled="
              companyInformation.NO_FACTURACION === 'S' ||
              company.NO_FACTURACION === 'S'
            "
          ></b-input>
        </b-field>

        <b-field
          expanded
          label-position="inside"
          :type="{ 'is-danger': companyInvoiceRequiredErrors.LLAVE_SAT }"
          :message="{
            'La llave SAT en base 64 no es válida':
              companyInvoiceRequiredErrors.LLAVE_SAT,
          }"
        >
          <template slot="label">
            Llave SAT en base 64 (Requerido)
            <b-tooltip
              multilined
              label="Si no cuenta con su llave privada SAT en base 64 puede subir el archivo .key en el botón 'Subir llave privada SAT' y nosotros transformaremos la llave a base 64"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: MIIFuzCCA6OgAwIBAgIUMzAwMDEwMDAwMDA0MDAwMDI..."
            expanded
            icon="directions-fork"
            v-model="company.LLAVE_SAT"
            password-reveal
            type="password"
            required
            :disabled="
              companyInformation.NO_FACTURACION === 'S' ||
              company.NO_FACTURACION === 'S'
            "
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Clave de llave privada SAT (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': companyInvoiceRequiredErrors.CLAVE_LLAVE }"
          :message="{
            'La clave de llave privada SAT no es válida':
              companyInvoiceRequiredErrors.CLAVE_LLAVE,
          }"
        >
          <b-input
            placeholder="Ejemplo: 1234ABC"
            expanded
            icon="file-outline"
            v-model="company.CLAVE_LLAVE"
            password-reveal
            type="password"
            required
            :disabled="
              companyInformation.NO_FACTURACION === 'S' ||
              company.NO_FACTURACION === 'S'
            "
          ></b-input>
        </b-field>
      </b-field>

      <div class="configuration-add-global-controls">
        <b-button type="is-success" @click="updateCompanyInvoiceInformation()"
          >Guardar cambios</b-button
        >
      </div>
    </div>

    <div class="configuration-divided-information special-margin">
      <h3 class="configuration-form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-field
          expanded
          label-position="inside"
          :type="{ 'is-danger': companyRequiredErrors.CP }"
          :message="{
            'El código postal no es válido': companyRequiredErrors.CP,
          }"
        >
          <template slot="label">
            Código Postal (Requerido)
            <b-tooltip
              multilined
              label="Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: 00000"
            expanded
            icon="directions-fork"
            v-model="company.CP"
            v-cleave="masks.customCP"
            @input="(value) => searchForDirectionInformation(value)"
            type="number"
            step="any"
            required
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Dirección (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Paseo del árbol #229"
            expanded
            icon="file-outline"
            v-model="company.DOMICIL"
          ></b-input>
        </b-field>
        <b-field label="Referencia (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Casa azul"
            expanded
            icon="file-outline"
            v-model="company.REFERENCIA"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Número exterior (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 250"
            expanded
            icon="counter"
            v-model="company.NUM_EXTER"
          ></b-input>
        </b-field>
        <b-field
          label="Número interior (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 10"
            expanded
            icon="counter"
            v-model="company.NUM_INTER"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Municipio (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Nuevo León"
            expanded
            icon="sign-real-estate"
            v-model="company.NOMBRE_MUNICIPIO"
          ></b-input>
        </b-field>
        <b-field
          label="Clave de municipio (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 001"
            expanded
            icon="city-variant-outline"
            v-model="company.MUNICIPIO"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Estado (Opcional)" expanded label-position="inside">
          <b-select
            placeholder="Ejemplo: Nuevo León"
            expanded
            icon="sign-real-estate"
            v-model="company.ESTADO"
          >
            <option value="" disabled>Seleccione un estado</option>
            <option
              v-for="singleState in states"
              :value="singleState.CLAVE"
              :key="singleState.NOMBRE"
            >
              {{ singleState.NOMBRE }}
            </option>
          </b-select>
        </b-field>
        <b-field
          label="Clave de la localidad (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 01"
            expanded
            icon="sign-real-estate"
            v-model="company.LOCALIDAD"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field expanded label-position="inside">
          <template slot="label">
            Nombre de la Colonia (Opcional)
            <b-tooltip
              multilined
              label="El listado de colonias disponibles aparece una vez llenado el código postal"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-autocomplete
            :data="filteredColonies"
            field="colony"
            open-on-focus
            placeholder="Ejemplo: Del Norte"
            expanded
            icon="home-group"
            v-model="company.NOMBRE_COLONIA"
            clearable
            @select="(option) => (company.NOMBRE_COLONIA = option.colony)"
          >
            <template #empty>No se encontraron colonias</template>
          </b-autocomplete>
        </b-field>

        <b-field
          label="Clave de la colonia (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 0001"
            expanded
            icon="sign-real-estate"
            v-model="company.COLONIA"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Teléfono (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 3300000000"
            expanded
            icon="phone"
            v-model="company.TELS"
            v-cleave="masks.customPhone"
          ></b-input>
        </b-field>
        <b-field
          label="Teléfono celular (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 3300000000"
            expanded
            icon="cellphone"
            v-model="company.CEL"
            v-cleave="masks.customPhone"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Email de contacto (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: compañia@mail.com"
            expanded
            icon="at"
            v-model="company.EMAIL"
          ></b-input>
        </b-field>
      </b-field>

      <h3 class="configuration-form-division">Información de contacto</h3>

      <b-field grouped class="special-margin">
        <b-field label="Nombre (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Pedro"
            expanded
            icon="account-box-outline"
            v-model="company.NOMBRE"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Apellido Paterno (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Rodriguez"
            expanded
            icon="account-multiple-outline"
            v-model="company.PATER"
          ></b-input>
        </b-field>
        <b-field
          label="Apellido Materno (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Pérez"
            expanded
            icon="account-child"
            v-model="company.MATER"
          ></b-input>
        </b-field>
      </b-field>

      <h3 class="configuration-form-division">Configuración general</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Email reenvio de errores (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: compañia@mail.com"
            expanded
            icon="at"
            v-model="company.EMAIL_ERRORES_FACTURACION"
          ></b-input>
        </b-field>
      </b-field>

      <b-field class="special-margin left-list-filters">
        <b-checkbox v-model="company.MOVIMIENTO_OBLIGATORIO"
          >Requerir movimiento bancario al crear pagos y anticipos</b-checkbox
        >
      </b-field>

      <b-field class="special-margin left-list-filters">
        <b-checkbox v-model="company.ENVIAR_MATERIAL_VENTA"
          >Marcar material como enviado en venta de manera
          predeterminada</b-checkbox
        >
      </b-field>

      <b-field class="special-margin left-list-filters">
        <b-checkbox v-model="company.PERMITIR_ENVIO_SIN_PAGO"
          >Permitir envios de material de ventas sin pago completo</b-checkbox
        >
      </b-field>

      <b-field class="special-margin left-list-filters">
        <b-checkbox v-model="company.AUTOCOMPLETAR_CODIGO_ALEATORIO"
          >Autocompletar código aleatorio único de manera
          predeterminada</b-checkbox
        >
      </b-field>

      <b-field class="special-margin left-list-filters">
        <b-checkbox v-model="company.NO_VENDER_CUENTAS_CLIENTE"
          >No poder vender si el cliente tiene cuentas vencida</b-checkbox
        >
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label-position="inside"
          expanded
          label="Lista de precios predeterminada al agregar cliente"
        >
          <b-select
            placeholder="Ejemplo: General"
            icon="clipboard-outline"
            expanded
            v-model="company.LISTA_PRECIOS_PRE"
          >
            <option value="" disabled>Seleccione una lista de precios</option>
            <option
              :value="singlePriceList._id"
              v-for="singlePriceList in priceLists"
              :key="singlePriceList._id"
            >
              {{ singlePriceList.CLAVE_LISTAP }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Porcentaje mayor permitido en revisión de facturas compras (Opcional)"
          expanded
          label-position="inside"
        >
          <PercentageInput
            placeholder="Ejemplo: Rodriguez"
            expanded
            type="number"
            step="any"
            icon="percent"
            v-model="company.POR_SUP_REVISION_COMPRAS"
          ></PercentageInput>
        </b-field>
        <b-field
          label="Porcentaje menor permitido en revisión de facturas compras (Opcional)"
          expanded
          label-position="inside"
        >
          <PercentageInput
            expanded
            type="number"
            step="any"
            icon="percent"
            v-model="company.POR_INF_REVISION_COMPRAS"
          ></PercentageInput>
        </b-field>
      </b-field>

      <div class="configuration-add-global-controls">
        <b-button type="is-success" @click="updateCompanyInformation()"
          >Guardar cambios</b-button
        >
      </div>
    </div>

    <div class="configuration-divided-information special-margin">
      <h3 class="configuration-form-division">
        Configuración de formatos impresos
      </h3>

      <div
        class="special-margin global-centered-start"
        v-if="
          companyInformation.paymentPlan.modules.main.customDocuments === 'S'
        "
      >
        <p class="special-margin label">Logo</p>

        <img
          v-if="company.LOGO_URL"
          :src="company.LOGO_URL"
          alt="Company logo"
          class="company-logo-preview special-margin"
        />

        <b-field
          class="file is-primary"
          :class="{ 'has-name': !!logoFile.name }"
        >
          <b-upload
            v-model="logoFile"
            class="file-label special-margin"
            accept="image/png"
            required
            validationMessage="Please select a file"
            @input="(value) => formatLogoInformation(value)"
          >
            <span class="file-cta">
              <span class="file-label">{{
                logoFile.name
                  ? logoFile.name
                  : "Haz click aqui para subir tu logo (Solo png max 2mb)"
              }}</span>
            </span>
          </b-upload>
        </b-field>
      </div>

      <div class="special-margin global-centered-start">
        <p class="special-margin label">Fondo</p>
        <v-swatches
          v-model="company.FONDO"
          show-fallback
          popover-x="left"
          shapes="circles"
          show-border
          class="special-margin"
        ></v-swatches>
      </div>

      <div class="special-margin global-centered-start">
        <p class="special-margin label">Fondo secundario</p>

        <v-swatches
          v-model="company.FONDO_SECUNDARIO"
          show-fallback
          popover-x="left"
          shapes="circles"
          show-border
          class="special-margin"
        ></v-swatches>
      </div>

      <div class="special-margin global-centered-start">
        <p class="special-margin label">Texto</p>

        <v-swatches
          v-model="company.TEXTO"
          show-fallback
          popover-x="left"
          shapes="circles"
          show-border
          class="special-margin"
        ></v-swatches>
      </div>

      <div class="special-margin global-centered-start">
        <p class="special-margin label">Texto secundario</p>

        <v-swatches
          v-model="company.TEXTO_SECUNDARIO"
          show-fallback
          popover-x="left"
          shapes="circles"
          show-border
          class="special-margin"
        ></v-swatches>
      </div>

      <b-field grouped class="special-margin">
        <b-field
          label="Leyenda superior (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Este es un documento impreso"
            expanded
            icon="arrow-up-bold"
            v-model="company.LEYENDA_SUPERIOR"
          ></b-input>
        </b-field>
        <b-field
          label="Leyenda inferior (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Para más información viste Coconut Control"
            expanded
            icon="arrow-down-bold"
            v-model="company.LEYENDA_INFERIOR"
          ></b-input>
        </b-field>
      </b-field>

      <div class="configuration-add-global-controls">
        <b-button type="is-success" @click="openPrintTestingFormat()"
          >Ver archivo de muestra</b-button
        >

        <b-button type="is-success" @click="updateCompanyInformationDocuments()"
          >Guardar cambios</b-button
        >
      </div>
    </div>

    <div class="configuration-divided-information special-margin">
      <h3 class="configuration-form-division">
        Configuración de notificaciones
      </h3>

      <div class="special-margin global-centered-start">
        <b-field grouped class="special-margin">
          <b-field
            label="Envio de notificaciones de creación de pedidos (Opcional)"
            expanded
            label-position="inside"
          >
            <b-taginput
              :data="users"
              v-model="this.company.NOT_COMPRAS_EMAILS"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="email"
              icon="account"
              placeholder="Usuarios"
              @add="(value) => addUser(value, 'NOT_COMPRAS')"
              @remove="(value) => removeUser(value, 'NOT_COMPRAS')"
            >
              <template #empty>No se encontraron usuarios</template>
            </b-taginput>
          </b-field>
          <b-field
            label="Envio de notificaciones de confirmación de pedidos (Opcional)"
            expanded
            label-position="inside"
          >
            <b-taginput
              :data="users"
              v-model="this.company.NOT_COMPRAS_CON_EMAILS"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="email"
              icon="account"
              placeholder="Usuarios"
              @add="(value) => addUser(value, 'NOT_COMPRAS_CON')"
              @remove="(value) => removeUser(value, 'NOT_COMPRAS_CON')"
            >
              <template #empty>No se encontraron usuarios</template>
            </b-taginput>
          </b-field>
        </b-field>
        <b-field grouped class="special-margin">
          <b-field
            label="Envio de notificaciones de creación de ventas (Opcional)"
            expanded
            label-position="inside"
          >
            <b-taginput
              :data="users"
              v-model="this.company.NOT_VENTAS_EMAILS"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="email"
              icon="account"
              placeholder="Usuarios"
              @add="(value) => addUser(value, 'NOT_VENTAS')"
              @remove="(value) => removeUser(value, 'NOT_VENTAS')"
            >
              <template #empty>No se encontraron usuarios</template>
            </b-taginput>
          </b-field>
        </b-field>
      </div>

      <div class="configuration-add-global-controls">
        <b-button
          type="is-success"
          @click="updateCompanyInformationNotifications()"
          >Guardar cambios</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import InformationCard from "../../Global/components/InformationCard.vue";
import {
  checkValidationErrors,
  transformFileToBase64,
  validateFields,
} from "@/utils/fns";
import {
  proPlusServicesPlanMonth,
  proPlusPlanMonth,
  masterPlanMonth,
} from "@/utils/env-variables-fns";
import States from "../../../data/states";
import Masks from "../../../data/cleave-masks";
import VSwatches from "vue-swatches";
import PrintTestingFormat from "../../Global/components/print-formats/PrintTestingFormat.vue";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "CompanyInformation",
  components: {
    InformationCard,
    VSwatches,
  },
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      company: {
        NO_FACTURACION: "N",
        NOMBRE_FAC: "",
        CERTIFICADO_SAT: "",
        LLAVE_SAT: "",
        CLAVE_LLAVE: "",
        CP: "",
        DOMICIL: "",
        REFERENCIA: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO: "",
        ESTADO: "",
        LOCALIDAD: "",
        COLONIA: "",
        NOMBRE_COLONIA: "",
        TELS: "",
        CEL: "",
        PATER: "",
        MATER: "",
        NOMBRE: "",
        GIRO_EMPRESA: "",
        CLAVE_REGIMEN: "",
        EMAIL_ERRORES_FACTURACION: "",
        MOVIMIENTO_OBLIGATORIO: false,
        ENVIAR_MATERIAL_VENTA: false,
        PERMITIR_ENVIO_SIN_PAGO: false,
        AUTOCOMPLETAR_CODIGO_ALEATORIO: false,
        NO_VENDER_CUENTAS_CLIENTE: false,
        LISTA_PRECIOS_PRE: "",
        LOGO: "",
        FONDO: "000000",
        FONDO_SECUNDARIO: "000000",
        TEXTO: "000000",
        TEXTO_SECUNDARIO: "000000",
        LEYENDA_SUPERIOR: "",
        LEYENDA_INFERIOR: "",
        NOT_COMPRAS: [],
        NOT_COMPRAS_CON: [],
        NOT_VENTAS: [],
        NOT_COMPRAS_EMAILS: [],
        NOT_COMPRAS_CON_EMAILS: [],
        NOT_VENTAS_EMAILS: [],
        LOGO_ID: "",
        LOGO_URL: "",
        POR_SUP_REVISION_COMPRAS: 0,
        POR_INF_REVISION_COMPRAS: 0,
      },
      companyInvoiceRequiredErrors: {},
      companyRequiredErrors: {
        CP: false,
      },
      masks: Masks,
      states: States,
      currentColonies: [],
      logoFile: {},
      proPlusServicesPlanMonth: proPlusServicesPlanMonth,
      proPlusPlanMonth: proPlusPlanMonth,
      masterPlanMonth: masterPlanMonth,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc updated the current company information
     */
    async updateCompanyInformation() {
      try {
        this.companyRequiredErrors = validateFields(
          this.company,
          this.companyRequiredErrors
        );
        if (checkValidationErrors(this.companyRequiredErrors)) {
          let response = await this.$store.dispatch(
            "UPDATECOMPANYINFORMATION",
            this.company
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardaron exitosamente los cambios",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar los cambios en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar los cambios en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar los cambios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc updated the current company information documents
     */
    async updateCompanyInformationDocuments() {
      try {
        let response = await this.$store.dispatch(
          "UPDATECOMPANYINFORMATIONDOCUMENTS",
          this.company
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardaron exitosamente los cambios",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar los cambios en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar los cambios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc updated the current company information notifications
     */
    async updateCompanyInformationNotifications() {
      try {
        let response = await this.$store.dispatch(
          "UPDATECOMPANYINFORMATIONNOTIFICATIONS",
          this.company
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardaron exitosamente los cambios",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar los cambios en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar los cambios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async updateCompanyInvoiceInformation() {
      try {
        this.companyInvoiceRequiredErrors = validateFields(
          this.company,
          this.companyInvoiceRequiredErrors
        );
        if (checkValidationErrors(this.companyInvoiceRequiredErrors)) {
          let response = await this.$store.dispatch(
            "UPDATECOMPANYINVOICEINFORMATION",
            this.company
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardaron exitosamente los cambios",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar los cambios en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar los cambios en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar los cambios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc searches for direction information when zip has the corret format
     */
    async searchForDirectionInformation(zipCode) {
      if (zipCode.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          { zipCode }
        );
        if (response.length > 0) {
          this.company.ESTADO = response[0].state_code;
          this.company.NOMBRE_MUNICIPIO = response[0].municipality;
          this.company.MUNICIPIO = response[0].municipio_code;
          this.company.LOCALIDAD = response[0].localidad_code;
          this.company.NOMBRE_COLONIA = response[0].colony;
          this.company.COLONIA = response[0].colony_code;
          this.currentColonies = response;
        }
      }
    },
    /**
     *
     * @desc opens stripe portal
     */
    async openStripePortal() {
      const response = await this.$store.dispatch("STRIPEPORTAL", {});
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar el servidor de pagos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    /**
     *
     * @desc opens checkout page to add stamps
     */
    async openStampCheckouts() {
      const response = await this.$store.dispatch("STRIPECHECKOUTSTAMPS", {});
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    /**
     *
     * @desc opens checkout page to add users
     */
    async openUsersCheckout() {
      const response = await this.$store.dispatch("STRIPECHECKOUTUSERS", {});
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    /**
     *
     * @desc opens checkout page to add point of sales
     */
    async openPosCheckout() {
      const response = await this.$store.dispatch("STRIPECHECKOUTPOS", {});
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    /**
     *
     * @desc opens checkout page to add custom cfdi documents
     */
    async openCFDIDocumentsCheckout() {
      const response = await this.$store.dispatch(
        "STRIPECHECKOUTCFDIDOCUMENTS",
        {}
      );
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    /**
     *
     * @desc opens checkout page to add company name
     */
    async openCompanyNameCheckout() {
      const response = await this.$store.dispatch(
        "STRIPECHECKOUTCOMPANYNAME",
        {}
      );
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    /**
     *
     * @desc opens cancel page
     */
    async openCancelSubscription() {
      window.open(
        "https://billing.stripe.com/p/login/dR6eXmady0Um5X23cc",
        "_blank"
      );
    },
    /**
     *
     * @desc opens checkout page to add warehouse
     */
    async openWarehousesCheckout() {
      const response = await this.$store.dispatch(
        "STRIPECHECKOUTWAREHOUSE",
        {}
      );
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_blank");
    },
    async transformCertificate(file) {
      const transformedFile = await transformFileToBase64(file);
      const result = transformedFile.split("base64,");
      this.company.CERTIFICADO_SAT = result[1];
    },
    async transformKey(file) {
      const transformedFile = await transformFileToBase64(file);
      const result = transformedFile.split("base64,");
      this.company.LLAVE_SAT = result[1];
    },
    openPrintTestingFormat() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintTestingFormat,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    addUser(user, list) {
      this.company[list].push(user._id);
    },
    removeUser(user, list) {
      for (const [singleUserIndex, singleUser] of this.company[
        list
      ].entries()) {
        if (singleUser === user._id) {
          this.company[list].splice(singleUserIndex, 1);
          return;
        }
      }
    },
    async formatLogoInformation(file) {
      if (file.size > 2097152) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "El tamaño del archivo es mayor a 2mb",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.logo = {};
        return;
      }

      this.company.LOGO = await transformFileToBase64(file);
    },
  },
  async mounted() {
    this.company.CP = this.companyInformation.CP;
    this.company.DOMICIL = this.companyInformation.DOMICIL;
    this.company.REFERENCIA = this.companyInformation.REFERENCIA;
    this.company.NUM_EXTER = this.companyInformation.NUM_EXTER;
    this.company.NUM_INTER = this.companyInformation.NUM_INTER;
    this.company.NOMBRE_MUNICIPIO = this.companyInformation.NOMBRE_MUNICIPIO;
    this.company.MUNICIPIO = this.companyInformation.MUNICIPIO;
    this.company.ESTADO = this.companyInformation.ESTADO;
    this.company.LOCALIDAD = this.companyInformation.LOCALIDAD;
    this.company.COLONIA = this.companyInformation.COLONIA;
    this.company.NOMBRE_COLONIA = this.companyInformation.NOMBRE_COLONIA;
    this.company.TELS = this.companyInformation.TELS;
    this.company.CEL = this.companyInformation.CEL;
    this.company.PATER = this.companyInformation.PATER;
    this.company.MATER = this.companyInformation.MATER;
    this.company.NOMBRE = this.companyInformation.NOMBRE;
    this.company.NOMBRE_FAC = this.companyInformation.NOMBRE_FAC;
    this.company.NO_FACTURACION = this.companyInformation.NO_FACTURACION;
    this.company.CERTIFICADO_SAT = this.companyInformation.CERTIFICADO_SAT;
    this.company.LLAVE_SAT = this.companyInformation.LLAVE_SAT;
    this.company.CLAVE_LLAVE = this.companyInformation.CLAVE_LLAVE;
    this.company.CLAVE_REGIMEN = this.companyInformation.CLAVE_REGIMEN;
    this.company.EMAIL_ERRORES_FACTURACION =
      this.companyInformation.EMAIL_ERRORES_FACTURACION;
    this.company.MOVIMIENTO_OBLIGATORIO =
      this.companyInformation.MOVIMIENTO_OBLIGATORIO;
    this.company.ENVIAR_MATERIAL_VENTA =
      this.companyInformation.ENVIAR_MATERIAL_VENTA;
    this.company.PERMITIR_ENVIO_SIN_PAGO =
      this.companyInformation.PERMITIR_ENVIO_SIN_PAGO;
    this.company.AUTOCOMPLETAR_CODIGO_ALEATORIO =
      this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO;
    this.company.NO_VENDER_CUENTAS_CLIENTE =
      this.companyInformation.NO_VENDER_CUENTAS_CLIENTE;
    this.company.LISTA_PRECIOS_PRE = this.companyInformation.LISTA_PRECIOS_PRE;
    this.company.POR_SUP_REVISION_COMPRAS =
      this.companyInformation.POR_SUP_REVISION_COMPRAS;
    this.company.POR_INF_REVISION_COMPRAS =
      this.companyInformation.POR_INF_REVISION_COMPRAS;
    this.company.FONDO = this.companyInformation.FONDO;
    this.company.FONDO_SECUNDARIO = this.companyInformation.FONDO_SECUNDARIO;
    this.company.TEXTO = this.companyInformation.TEXTO;
    this.company.TEXTO_SECUNDARIO = this.companyInformation.TEXTO_SECUNDARIO;
    this.company.LEYENDA_SUPERIOR = this.companyInformation.LEYENDA_SUPERIOR;
    this.company.LEYENDA_INFERIOR = this.companyInformation.LEYENDA_INFERIOR;
    this.company.LOGO_ID = this.companyInformation.LOGO_ID;
    this.company.LOGO_URL = this.companyInformation.LOGO_URL;
    this.company.NOT_COMPRAS = this.companyInformation.NOT_COMPRAS;
    this.company.NOT_COMPRAS_CON = this.companyInformation.NOT_COMPRAS_CON;
    this.company.NOT_VENTAS = this.companyInformation.NOT_VENTAS;

    if (this.companyInformation.CP.length === 5) {
      let response = await this.$store.dispatch("SEARCHDIRECTIONINFORMATION", {
        zipCode: this.companyInformation.CP,
      });
      if (response.length > 0) {
        this.currentColonies = response;
      }
    }

    this.company.NOT_COMPRAS_EMAILS = [];
    for (const singleUser of this.users) {
      if (this.companyInformation.NOT_COMPRAS.includes(singleUser._id)) {
        this.company.NOT_COMPRAS_EMAILS.push(singleUser.email);
      }
    }

    this.company.NOT_COMPRAS_CON_EMAILS = [];
    for (const singleUser of this.users) {
      if (this.companyInformation.NOT_COMPRAS_CON.includes(singleUser._id)) {
        this.company.NOT_COMPRAS_CON_EMAILS.push(singleUser.email);
      }
    }

    this.company.NOT_VENTAS_EMAILS = [];
    for (const singleUser of this.users) {
      if (this.companyInformation.NOT_VENTAS.includes(singleUser._id)) {
        this.company.NOT_VENTAS_EMAILS.push(singleUser.email);
      }
    }
  },
  watch: {
    async companyInformation() {
      this.company.CP = this.companyInformation.CP;
      this.company.DOMICIL = this.companyInformation.DOMICIL;
      this.company.REFERENCIA = this.companyInformation.REFERENCIA;
      this.company.NUM_EXTER = this.companyInformation.NUM_EXTER;
      this.company.NUM_INTER = this.companyInformation.NUM_INTER;
      this.company.NOMBRE_MUNICIPIO = this.companyInformation.NOMBRE_MUNICIPIO;
      this.company.MUNICIPIO = this.companyInformation.MUNICIPIO;
      this.company.ESTADO = this.companyInformation.ESTADO;
      this.company.LOCALIDAD = this.companyInformation.LOCALIDAD;
      this.company.COLONIA = this.companyInformation.COLONIA;
      this.company.NOMBRE_COLONIA = this.companyInformation.NOMBRE_COLONIA;
      this.company.TELS = this.companyInformation.TELS;
      this.company.CEL = this.companyInformation.CEL;
      this.company.PATER = this.companyInformation.PATER;
      this.company.MATER = this.companyInformation.MATER;
      this.company.NOMBRE = this.companyInformation.NOMBRE;
      this.company.NO_FACTURACION = this.companyInformation.NO_FACTURACION;
      this.company.NOMBRE_FAC = this.companyInformation.NOMBRE_FAC;
      this.company.CERTIFICADO_SAT = this.companyInformation.CERTIFICADO_SAT;
      this.company.LLAVE_SAT = this.companyInformation.LLAVE_SAT;
      this.company.CLAVE_LLAVE = this.companyInformation.CLAVE_LLAVE;
      this.company.CLAVE_REGIMEN = this.companyInformation.CLAVE_REGIMEN;
      this.company.EMAIL_ERRORES_FACTURACION =
        this.companyInformation.EMAIL_ERRORES_FACTURACION;
      this.company.MOVIMIENTO_OBLIGATORIO =
        this.companyInformation.MOVIMIENTO_OBLIGATORIO;
      this.company.ENVIAR_MATERIAL_VENTA =
        this.companyInformation.ENVIAR_MATERIAL_VENTA;
      this.company.PERMITIR_ENVIO_SIN_PAGO =
        this.companyInformation.PERMITIR_ENVIO_SIN_PAGO;
      this.company.AUTOCOMPLETAR_CODIGO_ALEATORIO =
        this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO;
      this.company.NO_VENDER_CUENTAS_CLIENTE =
        this.companyInformation.NO_VENDER_CUENTAS_CLIENTE;
      this.company.LISTA_PRECIOS_PRE =
        this.companyInformation.LISTA_PRECIOS_PRE;
      this.company.POR_SUP_REVISION_COMPRAS =
        this.companyInformation.POR_SUP_REVISION_COMPRAS;
      this.company.POR_INF_REVISION_COMPRAS =
        this.companyInformation.POR_INF_REVISION_COMPRAS;
      this.company.FONDO = this.companyInformation.FONDO;
      this.company.FONDO_SECUNDARIO = this.companyInformation.FONDO_SECUNDARIO;
      this.company.TEXTO = this.companyInformation.TEXTO;
      this.company.TEXTO_SECUNDARIO = this.companyInformation.TEXTO_SECUNDARIO;
      this.company.LEYENDA_SUPERIOR = this.companyInformation.LEYENDA_SUPERIOR;
      this.company.LEYENDA_INFERIOR = this.companyInformation.LEYENDA_INFERIOR;
      this.company.LOGO_ID = this.companyInformation.LOGO_ID;
      this.company.LOGO_URL = this.companyInformation.LOGO_URL;
      this.company.NOT_COMPRAS = this.companyInformation.NOT_COMPRAS;
      this.company.NOT_COMPRAS_CON = this.companyInformation.NOT_COMPRAS_CON;
      this.company.NOT_VENTAS = this.companyInformation.NOT_VENTAS;

      if (this.companyInformation.CP.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          {
            zipCode: this.companyInformation.CP,
          }
        );
        if (response.length > 0) {
          this.currentColonies = response;
        }
      }

      this.company.NOT_COMPRAS_EMAILS = [];
      for (const singleUser of this.users) {
        if (this.companyInformation.NOT_COMPRAS.includes(singleUser._id)) {
          this.company.NOT_COMPRAS_EMAILS.push(singleUser.email);
        }
      }

      this.company.NOT_COMPRAS_CON_EMAILS = [];
      for (const singleUser of this.users) {
        if (this.companyInformation.NOT_COMPRAS_CON.includes(singleUser._id)) {
          this.company.NOT_COMPRAS_CON_EMAILS.push(singleUser.email);
        }
      }

      this.company.NOT_VENTAS_EMAIL = [];
      for (const singleUser of this.users) {
        if (this.companyInformation.NOT_VENTAS.includes(singleUser._id)) {
          this.company.NOT_VENTAS_EMAILS.push(singleUser.email);
        }
      }
    },
  },
  computed: {
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        return regimenWithProps;
      });
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    filteredColonies() {
      return this.currentColonies.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(this.company.NOMBRE_COLONIA.toLowerCase()) >= 0
        );
      });
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        return userWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/Configuration.css";
@import "../../Global/style/InitialDashboard.css";
</style>
